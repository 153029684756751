<template>
    <div>
    </div>
</template>

<script>
import contractors from './store/index.js'
export default {
    components: {
    },
    created() {
        if (!this.$store.hasModule("contractors"))
            this.$store.registerModule("contractors", contractors)
    }
}
</script>